<template>
	<v-container no-gutters fluid>
		<div class="pa-4" v-if="loading">
			<v-progress-circular :size="50" color="white" indeterminate></v-progress-circular>
		</div>

		<div class="pa-4" v-if="invalidSaleDevice">
			<v-alert border="left" colored-border type="warning" elevation="2" :value="true">
				Desculpe, parece que o seu dispositivo não suporta o módulo do PagSeguro.
			</v-alert>

			<v-btn color="primary" class="mt-4" @click="$router.push('/inicio')">Voltar</v-btn>
		</div>

		<v-row row wrap justify-center v-if="!loading && !saleCode && !transacao">
			<v-col cols="12">
				<p class="mb-0 text-h5 white--text">Comprar</p>
			</v-col>

			<v-col cols="12" v-if="selectedProduct && selectedProduct.code && userData && userData.id">
				<p class="white--text">Estamos quase lá! Confirme os dados abaixo para finalizarmos sua compra:</p>

				<v-card max-width="500">
					<v-card-title>{{ selectedProduct.name }}</v-card-title>
					<v-card-subtitle>{{ selectedProduct.description }}<br/>{{ BRL(selectedProduct.price).format() }}</v-card-subtitle>

					<v-card-text>
						<p class="mb-0 caption">Suas Informações:</p>
						Nome: <span class="text--primary">{{ userData.completeName }}</span><br />
						Email: <span class="text--primary">{{ userData.email }}</span>
						
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="success" @click="makeSale()">Finalizar Compra</v-btn>
					</v-card-actions>
				</v-card>

				<v-btn text color="white" small class="ml-0 mt-2" @click="cancelSale()">Cancelar</v-btn>
			</v-col>
		</v-row>

		<v-row row wrap justify-center v-if="!loading && (saleCode || transacao)">
			<v-col cols="12">
				<p class="text-h5 white--text">Venda Realizada com Sucesso!</p>
				<p class="mb-0 white--text">Estamos aguardando a confirmação do pagamento.</p>
				<p class="white--text">Enviaremos um email a você quando tudo estiver pronto.</p>

				<v-btn color="primary" @click="successGoBack()">Voltar</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import currency from 'currency.js'

export default {
	name: "Buy",
	props: ['transacao'],
	data() {
		return {
			loading: false,
			userData: [],
			productCode: null,
			saleCode: null,
			selectedProduct: [],
			processedSaleData: [],
			lightbox: null,
			invalidSaleDevice: false
		}
	},
	beforeMount() {
		// IMPORT CDN
		/*
		this.lightbox = document.createElement('script')
		this.lightbox.setAttribute('src', 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js')
		document.head.appendChild(this.lightbox)
		*/
	},
	mounted() {
		this.productCode = sessionStorage.getItem('fac-product')
		if (this.productCode) {
			this.fetchProducts()
		}
		else {
			this.$router.push('/inicio')
		}
	},
	beforeDestroy() {
		this.lightbox = null
	},
	methods: {
		BRL(value, precision) {
			return currency(value, { precision: precision || 2, separator: ".", decimal: ",", symbol: "R$ " })
		},
		fetchProducts() {
			if (this.productCode) {
				this.loading = true
				this.$store.dispatch('checkAuth').then(result => {
					if (result && result.content && result.content.id) {
						this.userData = result.content

						// TRANSACTION IS MADE
						if (this.transacao) {
							let transactionData = {
								client: this.userData.id,
								product: this.productCode,
								transaction: this.transacao,
								sale: sessionStorage.getItem('fac-product-sale'),
								checkout: sessionStorage.getItem('fac-product-checkout')
							}

							this.$store.dispatch('registerSale', transactionData).then(() => {
								this.loading = false
							}).catch(() => {
								this.loading = false
							})
						}
						else {
							this.$store.dispatch('fetchProducts').then(result => {
								if (result && result.length > 0) {
									for (let product of result) {
										if (product.code === this.productCode) {
											this.selectedProduct = product
										}
									}
								}

								this.loading = false
							})
						}
					}
				})
			}
		},
		makeSale() {
			if (this.selectedProduct && this.selectedProduct.code && this.userData && this.userData.id) {
				this.loading = true
				let saleData = {
					productCode: this.selectedProduct.code,
					clientCode: this.userData.id
				}

				this.$store.dispatch('checkoutSale', saleData).then(result => {
					if (result && result.checkout && result.sale) {
						sessionStorage.setItem('fac-product-sale', result.sale)
						sessionStorage.setItem('fac-product-checkout', result.checkout)
						setTimeout(() => {
							this.loading = false
							this.openPagSeguroLightbox(result.checkout)
						}, 500)
					}
				})
			}
		},
		openPagSeguroLightbox(checkout) {
			// CHECK FOR MOBILE
			if (this.$vuetify.breakpoint.mdAndUp) {
				try {
					PagSeguroLightbox(checkout,  {
						success: (transactionCode) => {
							this.saleCode = transactionCode
							let saleData = {
								product: this.selectedProduct.code,
								client: this.userData.id,
								sale: sessionStorage.getItem('fac-product-sale'),
								checkout: sessionStorage.getItem('fac-product-checkout'),
								transaction: transactionCode,
							}

							this.$store.dispatch('registerSale', saleData)
						},
						abort: () => {
							setTimeout(() => {
								this.cancelSale()
							}, 500)
						}
					})
				}
				catch (error) {
					this.invalidSaleDevice = true
				}
			}
			else {
				this.loading = true
				setTimeout(() => {
					window.open(`https://pagseguro.uol.com.br/v2/checkout/payment.html?code=${checkout}`, '_self')
				}, 500)
			}
		},
		successGoBack() {
			sessionStorage.removeItem('fac-product')
			sessionStorage.removeItem('fac-product-sale')
			sessionStorage.removeItem('fac-product-checkout')
			this.$router.push('/inicio')
		},
		cancelSale() {
			sessionStorage.removeItem('fac-product')
			sessionStorage.removeItem('fac-product-sale')
			sessionStorage.removeItem('fac-product-checkout')
			this.$router.push('/')
		}
	}
}
</script>
